export class CatalogBoxComponent implements CatalogBoxComponentInterface {
    protected componentElem: HTMLElement|null = null;
    protected catalogs: CatalogItems|null = null;

    protected lang: string = 'de';
    protected labels: Labels = {
        de: {
            iPaperBtn: 'iPaper',
            orderBtn: 'Bestellen'
        },
        fr: {
            iPaperBtn: ' iPaper',
            orderBtn: 'Commander'
        }
    };

    protected currentCatalogNr: string = '0';

    protected catalogItemImgElem: HTMLImageElement|null = null;
    protected catalogItemDescWrapElem: HTMLElement|null = null;

    protected getLinkElem (link: string, lbl: string, type?: string): HTMLElement {
        const anchorElem = document.createElement('a');
        anchorElem.href = link;
        anchorElem.innerHTML = `<span>${lbl}</span>`;
        anchorElem.className = 'btn btn-outline-black';
        if (type) {
            anchorElem.className += ` btn-${type}`;
        }
        anchorElem.target = '_blank';
        return anchorElem;
    }

    protected replaceCatalogImage (nr: number): boolean {
        if (this.catalogs && this.catalogItemImgElem) {
            this.catalogItemImgElem.src = this.catalogs[nr].image;
            this.catalogItemImgElem.alt = this.catalogs[nr].title;
            return true;
        }
        return false;
    }

    protected replaceCatalogTextAndLinks (nr: number): void {
        if (!this.catalogs || !this.catalogItemDescWrapElem) {
            return;
        }

        const h4Tag = document.createElement('h4');
        h4Tag.textContent = this.catalogs[nr].title;
        const pTag = document.createElement('p');
        pTag.textContent = this.catalogs[nr].description;

        this.catalogItemDescWrapElem.innerHTML = '';
        this.catalogItemDescWrapElem.appendChild(h4Tag);
        this.catalogItemDescWrapElem.appendChild(pTag);
        if (this.catalogs[nr].iPaperLink) {
            this.catalogItemDescWrapElem.appendChild(this.getLinkElem(this.catalogs[nr].iPaperLink, this.labels[this.lang].iPaperBtn, 'catalog-ipaper'));
        }
        if (this.catalogs[nr].orderLink) {
            this.catalogItemDescWrapElem.appendChild(this.getLinkElem(this.catalogs[nr].orderLink, this.labels[this.lang].orderBtn, 'catalog-order'));
        }
    }

    protected switchCatalogThumbnail (nr: string): void {
        const curCatalogThumbnailElem = this.componentElem?.querySelector('#catalog-thumbnail-' + this.currentCatalogNr);
        if (curCatalogThumbnailElem) {
            curCatalogThumbnailElem.classList.remove('d-none');
        }
        const focusCatalogThumbnailElem = this.componentElem?.querySelector('#catalog-thumbnail-' + nr);
        if (focusCatalogThumbnailElem) {
            focusCatalogThumbnailElem.classList.add('d-none');
        }
        this.currentCatalogNr = nr;
    }

    constructor (language: string) {
        this.lang = language;
    }

    init (componentId: string, catalogs: CatalogItems): void {
        this.catalogs = catalogs;

        this.componentElem = document.getElementById(componentId);
        if (!this.componentElem) {
            return;
        }
        this.catalogItemImgElem = <HTMLImageElement> this.componentElem.querySelector('img#current-catalog');
        this.catalogItemDescWrapElem = this.componentElem.querySelector('#catalog-description-wrap');
    }

    focusCatalog (nr: number): void {
        if (this.catalogs && this.catalogs[nr]) {
            if (this.replaceCatalogImage(nr)) {
                this.replaceCatalogTextAndLinks(nr);
                this.switchCatalogThumbnail(nr.toString());
            }
        }
    }
}
