// Version 1.0.0, created for kuoni.ch
//
import { debounce } from './debounce';

// concept based on https://github.com/TYPO3-Solr/ext-solr/issues/2832
//
interface SuggestedDocument {
    content: string,
    group: string,
    link: string,
    previewImage: string,
    title: string
    type: string
}
interface TextSuggestions {
    [key: string]: number
}
interface SuggestResults {
    didSecondSearch: boolean
    documents: SuggestedDocument[],
    suggestion: string,
    suggestions: TextSuggestions
}

interface SuggestOptions {
    [key: string]: string
}

export class SuggestController {
    protected elem: HTMLElement;
    protected o: SuggestOptions = {
        suggestionsResultTitle: 'Suggestions',
        documentsResultTitle: 'Pages',
        searchInputFieldClass: 'js-solr-q',
        layerDivClass: 'search-suggestions',
        resultsDivClass: 'suggest-results'
    };

    protected searchUrl: string = '';
    protected minLength: number = 3;

    protected suggestionsDiv: HTMLDivElement|null = null;

    constructor (elem: HTMLElement, searchUrl: string, options?: SuggestOptions, minLength?: number) {
        this.elem = elem;
        this.searchUrl = searchUrl;
        if (options) {
            Object.assign(this.o, options);
        }
        if (minLength) {
            this.minLength = minLength;
        }

        this.init();
    }

    protected init (): void {
        const [searchForm] = this.elem.getElementsByTagName('form');
        const [inputElem] = <HTMLCollectionOf<HTMLInputElement>> this.elem.getElementsByClassName(this.o.searchInputFieldClass);
        if (!searchForm || !inputElem) {
            return;
        }

        let [suggestionsLayerDiv] = <HTMLCollectionOf<HTMLDivElement>> this.elem.getElementsByClassName(this.o.layerDivClass);
        let [suggestResultsUl] = <HTMLCollectionOf<HTMLUListElement>> this.elem.getElementsByClassName(this.o.resultsDivClass);
        if (!suggestionsLayerDiv) {
            suggestionsLayerDiv = document.createElement('div');
            suggestionsLayerDiv.className = this.o.layerDivClass;

            suggestResultsUl = document.createElement('ul');
            suggestResultsUl.className = this.o.resultsDivClass;
            suggestResultsUl.setAttribute('role', 'listbox');
            suggestResultsUl.id = `${inputElem.id}-list`;

            suggestionsLayerDiv.appendChild(suggestResultsUl);
            searchForm.appendChild(suggestionsLayerDiv);
        }

        inputElem.addEventListener('input', debounce(() => {
            if (inputElem.value.length < this.minLength) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const uri = searchForm.getAttribute('data-suggest') + '?tx_solr[queryString]=' + inputElem.value;

            fetch(uri)
                .then((response) => {
                    return response.json();
                })
                .then((data: SuggestResults) => {
                    if (!suggestResultsUl) {
                        return false;
                    }
                    suggestResultsUl.innerHTML = '';

                    if (!data) {
                        return false;
                    }
                    let numSuggestions = 0;
                    if (data.suggestions) {
                        if (this.o.suggestionsResultTitle) {
                            suggestResultsUl.innerHTML += '<li class="suggest-group-header" tabindex="-1">' + this.o.suggestionsResultTitle + '</li>';
                        }
                        Object.entries(data.suggestions).forEach((elem: [string, number], index: number) => {
                            numSuggestions++;
                            suggestResultsUl.innerHTML += `<li class="suggest-item suggest-item-suggestion" role="option" aria-posinset="${index + 1}" tabindex="-1"><a href="${this.searchUrl}?tx_solr[q]=${elem[0]}">${elem[0]}</a></li>`;
                        });
                    }
                    if (data.documents && data.documents.length > 0) {
                        if (this.o.documentsResultTitle) {
                            suggestResultsUl.innerHTML += '<li class="suggest-group-header" tabindex="-1">' + this.o.documentsResultTitle + '</li>';
                        }
                        data.documents.forEach((elem: SuggestedDocument, index: number) => {
                            if (elem.link !== '') {
                                suggestResultsUl.innerHTML += `<li class="suggest-item suggest-item-doc" role="option" aria-posinset="${numSuggestions + index + 1}" tabindex="-1"><a href="${elem.link}">${elem.title}</a></li>`;
                            }
                        });
                    }
                    if ((numSuggestions + data.documents.length) > 0) {
                        suggestionsLayerDiv.style.display = 'block';
                    }
                    return true;
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 300));

        inputElem.addEventListener('focus', function () {
            if (suggestResultsUl.innerHTML !== '') {
                suggestionsLayerDiv.style.display = 'block';
            }
        });

        document.addEventListener('click', (event) => {
            if (!searchForm.contains(<Node>event.target)) {
                suggestionsLayerDiv.style.display = 'none';
            }
        });
    }
}
