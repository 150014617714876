import LatLng = google.maps.LatLng;

interface GoogleMapsControllerInterface {
    setUseFontIconAsMarker(flag: boolean): void;
    setMarkerImage(markerImage: string): void
    setMarkerIcon(iconFont: string, icon: string, defaultColor: string, fontSize: string): void;
    setMarkerIconColor(color: string): void;

    showMultiMarkerMap(elemId: string, markers: GoogleMapsMarkerList, zoomLevel: number, connectMarkers: number): void;
    showBasicMap(containerId: string, lat: number, lon: number, infoWindowContent: string, zoomLevel?: number): void;
    showSalesSiteMap(containerId: string, lat: number, lon: number, infoWindowContent: string): void;
}

export class GoogleMapsController implements GoogleMapsControllerInterface {
    protected options: google.maps.MapOptions = {
        styles: [{ stylers: [{ visibility: 'simplified' }] }, { featureType: 'administrative.locality', elementType: 'labels.text.fill', stylers: [{ color: '#72573C' }] }, { featureType: 'administrative.neighborhood', elementType: 'labels.text.fill', stylers: [{ color: '#72573C' }, { saturation: -10 }, { lightness: 10 }] }, { featureType: 'landscape.man_made', elementType: 'geometry', stylers: [{ saturation: -60 }, { lightness: -5 }] }, { featureType: 'poi.business', stylers: [{ visibility: 'off' }] }, { featureType: 'poi.school', stylers: [{ visibility: 'off' }] }, { featureType: 'road', elementType: 'geometry', stylers: [{ hue: '#bbc0c4' }, { saturation: -93 }, { lightness: 31 }] }, { featureType: 'road', elementType: 'labels', stylers: [{ hue: '#bbc0c4' }, { saturation: -93 }, { lightness: 31 }] }, { featureType: 'road.local', elementType: 'geometry', stylers: [{ hue: '#e9ebed' }, { saturation: -90 }, { lightness: -8 }] }, { featureType: 'transit', stylers: [{ saturation: -100 }, { lightness: 10 }] }, { featureType: 'water', stylers: [{ color: '#99ccff' }] }]
        //, mapId: '413fc05bb4d08df' // see Google Cloud Console, Project Kuoni Services, Map management
    };

    protected useFontIconAsMarker: boolean = true;
    protected markerImage: string = '/typo3conf/ext/kuoni/Resources/Public/img/icons/map_marker.png';
    protected markerIconLabel = {
        fontFamily: 'iconkuoni',
        // eslint-disable-next-line no-useless-escape
        text: '\ue90b',
        color: '#212121',
        fontSize: '36px'
    };

    constructor (options?: google.maps.MapOptions) {
        if (options) {
            Object.assign(this.options, options);
        }
    }

    setUseFontIconAsMarker (flag: boolean): void {
        this.useFontIconAsMarker = flag;
    }

    setMarkerImage (markerImage: string): void {
        this.markerImage = markerImage;
    }

    setMarkerIcon (iconFont: string, icon: string, defaultColor: string, fontSize: string): void {
        this.markerIconLabel.fontFamily = iconFont;
        this.markerIconLabel.text = icon;
        this.markerIconLabel.color = defaultColor;
        this.markerIconLabel.fontSize = fontSize;
    }

    setMarkerIconColor (color: string): void {
        this.markerIconLabel.color = color;
    }

    getMarker (position: LatLng, map: google.maps.Map, title: string): google.maps.Marker {
        if (this.useFontIconAsMarker) {
            return new google.maps.Marker({
                position,
                map,
                icon: ' ',
                title,
                label: this.markerIconLabel
            });
        }
        return new google.maps.Marker({
            position,
            map,
            title
        });
    }

    showMultiMarkerMap (containerId: string, markers: GoogleMapsMarkerList, zoomLevel: number, connectMarkers: number): void {
        const mapContainer = document.getElementById(containerId);
        if (!mapContainer) {
            return;
        }
        // consent for Google Maps?
        if (!window.ucDps || !window.ucDps.googleMaps) {
            mapContainer.classList.add('no-consent');
            return;
        } else {
            mapContainer.classList.remove('no-consent');
        }
        const mapElem = <HTMLDivElement>mapContainer.querySelector('.gmap-item');
        if (!mapElem) {
            return;
        }

        window.googleMapsLoader.load().then(async (google) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

            const mapOptions = Object.assign(this.options, {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                streetViewControl: false,
                zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
                zoom: zoomLevel
            });
            const bounds = new google.maps.LatLngBounds();
            const infoWindow = new google.maps.InfoWindow();
            let marker, i;
            const routePathCoordinates = [];
            let position = null;

            const map = new Map(mapElem, mapOptions);
            for (i = 0; i < markers.length; i++) {
                position = new google.maps.LatLng(markers[i].lat, markers[i].lon);
                if (typeof zoomLevel === 'undefined' || zoomLevel === 0) {
                    bounds.extend(position);
                }
                marker = this.getMarker(position, map, markers[i].title);

                if (markers[i].info) {
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            infoWindow.setContent(markers[i].info);
                            infoWindow.open(map, marker);
                        };
                    })(marker, i));
                }

                // for polyline
                if (connectMarkers) {
                    routePathCoordinates[i] = { lat: markers[i].lat, lng: markers[i].lon };
                }
            }
            // connect markers
            if (connectMarkers && routePathCoordinates.length > 1) {
                const routePath = new google.maps.Polyline({
                    path: routePathCoordinates,
                    geodesic: true,
                    strokeColor: '#333333',
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                });
                routePath.setMap(map);
            }
            // eslint-disable-next-line promise/always-return
            if (typeof zoomLevel === 'undefined' || zoomLevel === 0) {
                if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                    const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.001, bounds.getNorthEast().lng() + 0.001);
                    const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.001, bounds.getNorthEast().lng() - 0.001);
                    bounds.extend(extendPoint1);
                    bounds.extend(extendPoint2);
                }
                map.fitBounds(bounds);
            } else {
                if (position) {
                    map.setCenter(position);
                }
                map.setZoom(zoomLevel);
            }
        })
            .catch((e: Error) => {
                console.log(e.message);
            });
    }

    showBasicMap (containerId: string, lat: number, lon: number, infoWindowContent: string, zoomLevel?: number, options?: google.maps.MapOptions): void {
        const mapContainer = document.getElementById(containerId);
        if (!mapContainer) {
            return;
        }
        // consent for Google Maps?
        if (!window.ucDps || !window.ucDps.googleMaps) {
            mapContainer.classList.add('no-consent');
            return;
        } else {
            mapContainer.classList.remove('no-consent');
        }
        const mapElem = <HTMLDivElement>mapContainer.querySelector('.gmap-item');
        if (!mapElem) {
            return;
        }

        // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-floating-promises
        window.googleMapsLoader.load().then(async (google) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

            if (!zoomLevel) {
                zoomLevel = 11;
            }
            // default options for basic map
            const mapOptions = Object.assign(this.options, {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                streetViewControl: false,
                zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
                zoom: zoomLevel
            });
            if (options) {
                Object.assign(mapOptions, options);
            }
            const infoWindow = new google.maps.InfoWindow();

            const map = new Map(mapElem, mapOptions);
            const position = new google.maps.LatLng(lat, lon);
            const marker = this.getMarker(position, map, '');
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    infoWindow.setContent(infoWindowContent);
                    infoWindow.open(map, marker);
                };
            })(marker));
            map.setCenter(position);

            return true;
        });
    }

    showSalesSiteMap (containerId: string, lat: number, lon: number, infoWindowContent: string): void {
        const mapDefaultZoom = 17;
        const mapOptions = { mapTypeControl: false, fullscreenControl: false };
        this.showBasicMap(containerId, lat, lon, infoWindowContent, mapDefaultZoom, mapOptions);
    }
}
